<template>
  <div class="holder"></div>
</template>

<script lang="ts">
 import { defineComponent } from "vue";
 export default defineComponent({
   name: "NavigatorHolder",
 })
</script>

<style lang="scss" scoped>
  .holder {
    padding-bottom: 66px;
  }
</style>