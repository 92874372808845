<template>
  <div>
    <navigator-holder></navigator-holder>
    <div class="wrapper">
      <div class="block">
        <h2 class="title">选择适合你的定价方案</h2>
        <p class="tips">我们为不同规模场馆设立了不同的定价。</p>
        <p class="tips">
          如果挑选时遇到疑问，<br v-if="isMobile" />可添加微信
          <span class="has-text-primary">xiedaimala03</span> 联系服务顾问。
        </p>
        <div class="is-flex is-justify-content-center">
          <img
            alt="定价"
            src="https://bingcao-docs.oss-cn-hangzhou.aliyuncs.com/docs-image/WechatIMG133.jpg"
            style="border-radius: 8px; margin-top: 3vh; width: 600px; max-width: 95vw;"
            data-zoomable
          />
          <!-- <table class="price-table default">
            <thead>
              <tr>
                <th>免费版</th>
                <th colspan="7">VIP5人以下，功能不限制</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-head">
                <td>专业版</td>
                <td>VIP会员人数</td>
                <td>一个月</td>
                <td>三个月</td>
                <td>半年价</td>
                <td class="promotion">
                  新店特惠<span class="promotion-tips">半年</span>
                </td>
                <td>一年价</td>
                <td class="promotion">
                  新店特惠<span class="promotion-tips">一年</span>
                </td>
              </tr>
              <tr>
                <td>工作室</td>
                <td>{{isMobile ? '≤30人' : '30人以下'}}</td>
                <td>¥120</td>
                <td>¥360</td>
                <td>¥720</td>
                <td class="promotion">¥680</td>
                <td>¥1440</td>
                <td class="promotion">¥1200</td>
              </tr>
              <tr>
                <td>中型店</td>
                <td>{{isMobile ? '≤300人' : '300人以下'}}</td>
                <td>¥200</td>
                <td>¥600</td>
                <td>¥1200</td>
                <td class="promotion">¥888</td>
                <td>¥2400</td>
                <td class="promotion">¥1580</td>
              </tr>
              <tr>
                <td>大型店</td>
                <td>{{isMobile ? '≤1000人' : '1000人以下'}}</td>
                <td>¥300</td>
                <td>¥900</td>
                <td>¥1800</td>
                <td class="promotion">¥1380</td>
                <td>¥3600</td>
                <td class="promotion">¥1890</td>
              </tr>
              <tr>
                <td>超大型俱乐部</td>
                <td>不限制</td>
                <td colspan="6">价格联系人工定制</td>
              </tr>
            </tbody>
          </table> -->
        </div>
      </div>

      <!-- <div class="block">
        <h3 class="vip-title">尊享套餐</h3>
        <p class="vip-tips">
          付费
          <span class="has-text-primary">1</span>年/<span
            class="has-text-primary"
            >2</span
          >年/<span class="has-text-primary">3</span
          >年的店铺，免费赠送定制专属小程序
        </p>
        <p class="vip-tips">
          <span class="has-text-primary">1</span>年/<span
            class="has-text-primary"
            >2</span
          >年/<span class="has-text-primary">3</span>年，价值2188/4376/6564元。
        </p>
        <div class="is-flex is-justify-content-center">
          <table class="price-table custom">
            <thead>
              <tr class="table-head">
                <th>专业版</th>
                <th>VIP会员人数</th>
                <th>两年套餐</th>
                <th>三年套餐</th>
                <th>四年套餐</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>工作室</td>
                <td>30人以下</td>
                <td>¥1899</td>
                <td>¥2688</td>
                <td>¥3328</td>
              </tr>
              <tr>
                <td>中型店</td>
                <td>300人以下</td>
                <td>¥2499</td>
                <td>¥3528</td>
                <td>¥4368</td>
              </tr>
              <tr>
                <td>大型店</td>
                <td>1000人以下</td>
                <td>¥2899</td>
                <td>¥4088</td>
                <td>¥5148</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->

      <div class="block">
        <h3 class="question-title">购买常见问题</h3>
        <div class="columns questions">
          <section class="question-block-wrapper">
            <h3>免费试用期还没结束，能续费吗？</h3>
            <p>
              可以。冰草约课可以免费试用7天，试用期结束前可续费，续费的有效期会在免费试用期后增加，也就是免费试用期的时长是保留不变的哦！
            </p>
          </section>
          <section class="question-block-wrapper">
            <h3>后续如何调整方案和人数？</h3>
            <p>
              用户可随时进入「我的」-「会员中心」页面升级人数和延长天数。调整的截止日期和当前付费后的截止日期保持一致，系统会自动为您计算所需支付的差价。
            </p>
          </section>
          <section class="question-block-wrapper">
            <h3>续费后如何咨询和售后？</h3>
            <p>
              每家店提供单独的VIP售后服务群，群内配备对接的顾问、产品和技术人员。如已续费，售后还未联系到您，请您添加顾问微信：<span
                class="has-text-primary"
                >xiedaimala03</span
              >
            </p>
          </section>
          <!-- <section class="question-block-wrapper">
            <h3>推荐朋友使用冰草约课有优惠吗？</h3>
            <p>
              当然。老客户推荐朋友入驻冰草，在优惠价基础上新人减100元/年，再给推荐的老客户返现100元。各自再赠送15天有效期。
            </p>
          </section> -->
          <section class="question-block-wrapper">
            <h3>场馆暂停营业或放长假如何处理？</h3>
            <p>可在售后群内申请暂停系统，暂停期间不计算有效期。</p>
          </section>
          <section class="question-block-wrapper">
            <h3>管理员换人了，如何处理？</h3>
            <p>
              如果是子管理员换人，店主可以在「场馆设置」-「添加管理员账号」中自行调整；如果店主换人，需要在售后群中核实后，售后人工处理。
            </p>
          </section>
          <section class="question-block-wrapper">
            <h3>如何获取合同？</h3>
            <p>请添加客服微信，可提供电子合同，如需纸质合同需要另付邮费。</p>
          </section>
          <div class="place-holder"></div>
          <div class="place-holder"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import NavigatorHolder from "@/components/NavigatorHolder.vue";
import { defineComponent, inject } from "vue";
export default defineComponent({
  components: { NavigatorHolder },
  name: "Price",
  setup() {
    return {
      isMobile: inject("isMobile"),
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/stylesheets/_helpers.scss";
.wrapper {
  position: relative;
  max-width: 1033px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.block {
  color: $remark-font-color;
  font-size: 14px;
  text-align: center;
  margin: 60px 0;
}

h2.title {
  font-size: 36px;
  font-weight: bold;
}

p.tips {
  color: $sub-font-color;
  font-size: 18px;
  line-height: 25px;
}

h3.vip-title,
h3.question-title {
  font-size: 24px;
  font-weight: bold;
  color: $title-font-color;
  margin-bottom: 10px;
}

p.vip-tips {
  font-size: 14px;
  color: $sub-font-color;
}

.price-table {
  margin-top: 40px;
  font-size: 20px;
}

.questions {
  margin: 40px -5% 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.question-block-wrapper {
  width: 370px;
  padding: 0 50px;
  flex-shrink: 0;
  margin-bottom: 40px;
  text-align: left;
  > h3 {
    color: $sub-font-color;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
  }
  > p {
    line-height: 20px;
  }
  @include phone() {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 0;
    margin: 10px 0;
    width: 80vw;
  }
}

td,
th {
  border: 1px solid #dddddd;
  padding: 16px 0;
  min-width: 120px;
}

table th {
  color: inherit;
  font-weight: normal;
}

tr > th:first-child,
tr > td:first-child {
  border: 1px solid $primary;
  min-width: 150px;
  font-weight: bold;
  color: $sub-font-color;
}

.table-head > th,
.table-head > td {
  color: $title-font-color;
}

tr > td:nth-child(2) {
  min-width: 150px;
}

.promotion {
  position: relative;
  color: $red !important;
}

.promotion-tips {
  color: $remark-font-color;
  font-size: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}

.place-holder {
  width: 370px;
}

@media (max-width: $width-desktop) {
  h2.title {
    font-size: 26px;
  }
  p.tips {
    font-size: 14px;
    line-height: 20px;
  }
  h3.vip-title,
  h3.question-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .questions {
    margin-top: 20px;
  }
}

@include phone() {
  .price-table {
    font-size: 12px;
  }

  .price-table.custom {
    width: 85%;
  }

  td,
  th {
    padding: 0;
    min-width: 50px;
    height: 38px;
    max-height: 48px;
    vertical-align: middle;
    text-align: center;
  }

  table th {
    color: inherit;
    font-weight: normal;
  }

  tr > th:first-child,
  tr > td:first-child {
    border: 1px solid $primary;
    min-width: 50px;
    font-weight: bold;
    color: $sub-font-color;
  }

  .table-head > th,
  .table-head > td {
    color: $title-font-color;
  }

  tr > td:nth-child(2) {
    min-width: 50px;
  }

  .price-table.default {
    td,
    th {
      width: 50px;
    }
    tr > th:first-child,
    tr > td:first-child {
      display: none;
    }
  }

  .promotion-tips {
    position: relative;
  }
}
</style>
