<template>
  <div>
    <navigator-holder></navigator-holder>
    <div class="customers-wrapper">
      <custom-title
        name="他们正在使用冰草约课"
        description="来看看各行各业是如何使用 <span class='has-text-primary'>冰草约课</span> 经营场馆的"
      />
      <div class="tab-headers">
        <section
          class="is-flex is-align-items-center tab-header"
          v-for="(config, index) in configs"
          :key="index"
          :class="`is-flex-direction-column${index % 2 ? '' : '-reverse'} ${
            activeCategoryKey === config.categoryKey ? 'active' : ''
          }`"
          @click="onTabChange(config.categoryKey)"
        >
          <svg-icon :name="`tab-icon-${config.categoryKey}`" class="tab-icon" />
          <h3 class="category-name" :class="`m${index % 2 ? 't' : 'b'}-4`">
            {{ config.categoryName }}
          </h3>
        </section>
      </div>
      <tabs :activeKey="activeCategoryKey">
        <tab-pane
          class="content-wrapper"
          v-for="config in configs"
          :key="config.categoryKey"
          :tab="config.categoryName"
        >
          <div class="cards-wrapper">
            <shop-card
              v-for="shop in config.shops"
              :key="shop.id"
              :name="shop.name"
              :avatar="
                require(`@/assets/images/customers/avatar-${shop.id}.png`)
              "
              :background="shop.background"
              :categoryName="config.categoryName"
              :id="shop.id"
            ></shop-card>
            <section class="holder"></section>
            <section class="holder"></section>
          </div>
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script lang="ts">
import CustomTitle from "@/components/CustomTitle.vue";
import NavigatorHolder from "@/components/NavigatorHolder.vue";
import ShopCard from "@/components/ShopCard.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { Tabs } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import configs from "./configs";
export default defineComponent({
  components: {
    NavigatorHolder,
    CustomTitle,
    Tabs,
    TabPane: Tabs.TabPane,
    ShopCard,
    SvgIcon,
  },
  name: "Customers",
  setup() {
    const categories = configs.map(c => c.categoryKey)
    const hashKey = window.location.hash.replace('#', '')
    const initialCategoryKey = categories.includes(hashKey) ? hashKey : 'dance'
    const activeCategoryKey = ref(initialCategoryKey);
    const onTabChange = (categoryKey: string) => {
      activeCategoryKey.value = categoryKey;
    };
    return {
      activeCategoryKey,
      onTabChange,
      configs,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/_helpers.scss";

.customers-wrapper {
  max-width: 1200px;
  min-height: calc(100vh - 66px - 245px);
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  padding: 40px 0 80px 0;
}

.tab-headers {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
  @include phone() {
    padding: 0 2vw;
    justify-content: space-around;
    margin-bottom: 40px;
  }
}

.tab-header {
  margin: 0 2vw;
  cursor: pointer;
  .category-name {
    font-size: 20px;
  }
  &.active {
    .tab-icon {
      box-shadow: 0 0 0 2px $primary;
    }
    .category-name {
      font-weight: bold;
    }
  }

  @include phone() {
    .category-name {
      font-size: 12px;
    }
  }
}

.tab-icon {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  max-width: 13vw;
  max-height: 13vw;
}

:deep(.ant-tabs-bar) {
  display: none;
}

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.holder {
  width: 340px;
  flex-shrink: 0;
  margin: 0 20px;
}
</style>