
import NavigatorHolder from "@/components/NavigatorHolder.vue";
import { defineComponent, inject } from "vue";
export default defineComponent({
  components: { NavigatorHolder },
  name: "Price",
  setup() {
    return {
      isMobile: inject("isMobile"),
    };
  },
});
