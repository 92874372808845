<template>
  <tooltip>
    <template v-slot:title v-if="!isMobile">
      <img
        :src="require(`@/assets/images/customers/qrcode-${id}.png`)"
        class="qrcode"
      />
    </template>
    <section class="wrapper" :style="{ background }" @click="onClick">
      <img :src="avatar" :alt="name" class="avatar" />
      <div
        class="is-flex is-flex-direction-column is-justify-content-space-between has-text-white text"
      >
        <h4>{{ name }}</h4>
        <p>{{ categoryName }}</p>
      </div>
    </section>
  </tooltip>
</template>

<script lang="ts">
import { defineComponent, inject, Ref } from "vue";
import { Tooltip } from "ant-design-vue";
export default defineComponent({
  name: "ShopCard",
  components: { Tooltip },
  props: ["name", "avatar", "categoryName", "background", "id"],
  setup(props) {
    const isMobile = inject<Ref<boolean>>('isMobile')
    const onClick = () => {
      if (!(isMobile && isMobile.value)) return;
      window.open(`https://dd.xiedaimala.com/shops/${props.id}`)
    }
    return {
      isMobile,
      onClick
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/_helpers.scss";

.wrapper {
  display: flex;
  flex-shrink: 0;
  width: 340px;
  height: 128px;
  border-radius: 10px;
  padding: 15px;
  margin: 25px 20px;
  cursor: pointer;
  > .avatar {
    width: 98px;
    height: 98px;
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.6));
  }
  .text {
    margin-left: 10px;
    h4 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.75);
    }
  }
  @include phone() {
    margin: 10px 20px;
  }
}
.qrcode {
  width: 140px;
  height: 140px;
  max-width: 60vw;
  max-height: 60vw;
  margin: 10px;
}
</style>