
import CustomTitle from "@/components/CustomTitle.vue";
import NavigatorHolder from "@/components/NavigatorHolder.vue";
import ShopCard from "@/components/ShopCard.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { Tabs } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import configs from "./configs";
export default defineComponent({
  components: {
    NavigatorHolder,
    CustomTitle,
    Tabs,
    TabPane: Tabs.TabPane,
    ShopCard,
    SvgIcon,
  },
  name: "Customers",
  setup() {
    const categories = configs.map(c => c.categoryKey)
    const hashKey = window.location.hash.replace('#', '')
    const initialCategoryKey = categories.includes(hashKey) ? hashKey : 'dance'
    const activeCategoryKey = ref(initialCategoryKey);
    const onTabChange = (categoryKey: string) => {
      activeCategoryKey.value = categoryKey;
    };
    return {
      activeCategoryKey,
      onTabChange,
      configs,
    };
  },
});
