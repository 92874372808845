const configs = [
  {
    categoryName: "舞蹈类",
    categoryKey: "dance",
    shops: [
      {
        name: "Heartbeat舞蹈音乐工作室",
        background:
          "linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.5) 100%)",
        id: "693",
      },
      {
        name: "寻舞诀舞蹈瑜伽中心",
        background:
          "linear-gradient(90deg, #F19A00 0%, rgba(241, 154, 0, 0.5) 100%)",
        id: "872",
      },
      {
        name: "Fox Dance 舞蹈工作室",
        background: "linear-gradient(90deg, #BC5250 0%, #E78379 100%)",
        id: "951",
      },
      {
        name: "艺立方舞蹈培训",
        background:
          "linear-gradient(90deg, #348FCD 0%, #D293B0 43.23%, #68C2B9 79.17%, #5F4D97 99.48%)",
        id: "914",
      },
      {
        name: "V舞蹈艺术中心",
        background:
          "linear-gradient(90deg, pink 0%, rgba(255, 182, 193, 0.5) 99.48%)",
        id: "1054",
      },
      {
        name: "XY DANCE STUDID",
        background:
          "linear-gradient(90deg, rgb(38, 39, 43) 0%, rgb(142, 141, 139) 52.6%, rgb(203, 203, 201) 100%)",
        id: "1773",
      },
      {
        name: "LaVida舞蹈工作室",
        background:
          "linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
        id: "1642",
      },
    ],
  },
  {
    categoryName: "运动类",
    categoryKey: "sport",
    shops: [
      {
        name: "晴天网球俱乐部",
        background:
          "linear-gradient(90deg, #58B077 0%, rgba(92, 181, 124, 0.5) 100%)",
        id: "377",
      },
      {
        name: "熊二体育",
        background:
          "linear-gradient(90deg,#254982 0%,#a19acd 51.04%,#cadffe 100%)",
        id: "1720",
      },
      {
        name: "美姿健身·Mz-Shape",
        background:
          "linear-gradient(90deg, rgb(88, 176, 119) 0%, rgba(92, 181, 124, 0.5) 100%)",
        id: "1810",
      },
      {
        name: "BIG BEE团课健身",
        background:
          "linear-gradient(90deg, rgb(251, 200, 55) 0%, rgba(251, 200, 55, 0.5) 100%)",
        id: "1667",
      },
      {
        name: "TOP ONE 团课与私教",
        background:
          "linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
        id: "1448",
      },
    ],
  },
  {
    categoryName: "瑜伽类",
    categoryKey: "yoga",
    shops: [
      {
        name: "澜心瑜伽馆",
        background: "linear-gradient(90deg, #3C1E87 0%, #E4007F 100%)",
        id: "547",
      },
      {
        name: "千舍瑜伽教室",
        background:
          "linear-gradient(90deg, #FBC837 0%, rgba(251, 200, 55, 0.5) 100%)",
        id: "750",
      },
      {
        name: "一然瑜伽",
        background:
          "linear-gradient(90deg, #92510D 0%, #E4B774 43.75%, #EDE5E1 100%)",
        id: "890",
      },
      {
        name: "玖合瑜伽湖居店",
        background:
          "linear-gradient(90deg, #5CAA94 0%, rgba(92, 170, 148, 0.5) 100%)",
        id: "633",
      },
      {
        name: "鹿瑜伽",
        background: "linear-gradient(90deg, #44639A 0%, #D7CBB7 100%)",
        id: "549",
      },
      {
        name: "梵一瑜伽",
        background:
          "linear-gradient(90deg, #FCDAB9 0%, #C19371 61.98%, #595451 100%)",
        id: "187",
      },
    ],
  },
  {
    categoryName: "幼教类",
    categoryKey: "child",
    shops: [
      {
        name: "小童学艺术团",
        background:
          "linear-gradient(90deg, #A3B7CF 0%, #E5BE79 51.04%, #DAE9EE 100%)",
        id: "823",
      },
      {
        name: "好朋友语数外精品课",
        background:
          "linear-gradient(90deg, rgb(245, 152, 66) 0%, rgb(79, 164, 168) 34.9%, rgb(0, 115, 168) 100%)",
        id: "165",
      },
      {
        name: "EAU",
        background:
          "linear-gradient(90deg, rgb(176, 45, 34) 0%, rgb(221, 123, 116) 34.37%, rgb(227, 191, 71) 68.75%, rgb(16, 150, 215) 100%)",
        id: "1703",
      },
    ],
  },
  {
    categoryName: "艺术类",
    categoryKey: "art",
    shops: [
      {
        name: "小筑艺术",
        background:
          "linear-gradient(90deg, #005BAC 0%, rgba(0, 91, 171, 0.5) 100%)",
        id: "273",
      },
      {
        name: "霁月音画社",
        background:
          "linear-gradient(90deg, #030303 0%, rgba(3, 3, 3, 0.5) 100%)",
        id: "146",
      },
      {
        name: "青杂美学",
        background:
          "linear-gradient(90deg, #2E98D7 0%, rgba(41, 152, 214, 0.5) 100%)",
        id: "166",
      },
    ],
  },
];

export default configs;
