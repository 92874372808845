
import { defineComponent, inject, Ref } from "vue";
import { Tooltip } from "ant-design-vue";
export default defineComponent({
  name: "ShopCard",
  components: { Tooltip },
  props: ["name", "avatar", "categoryName", "background", "id"],
  setup(props) {
    const isMobile = inject<Ref<boolean>>('isMobile')
    const onClick = () => {
      if (!(isMobile && isMobile.value)) return;
      window.open(`https://dd.xiedaimala.com/shops/${props.id}`)
    }
    return {
      isMobile,
      onClick
    }
  }
});
